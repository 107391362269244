import { render, staticRenderFns } from "./productlist.vue?vue&type=template&id=4b947108&scoped=true"
import script from "./productlist.vue?vue&type=script&lang=js"
export * from "./productlist.vue?vue&type=script&lang=js"
import style0 from "./productlist.vue?vue&type=style&index=0&id=4b947108&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b947108",
  null
  
)

export default component.exports