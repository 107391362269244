<template>
  <div>
    <product-list
      name="common.product"
      :max="0"
      class="product-list"
    />
  </div>
</template>
<script>
import ProductList from "@/components/ProductList";

export default {
  components: {
    ProductList,
  },
  data() {
    return {}
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.product-list {
  margin-bottom: 15px;
}
</style>